import request from '@/plugins/axios'

// 仓库管理列表
export const apiStorehouseList = (params: any) =>
    request.get('/warehouse.warehouse/lists', { params })
// 修改仓库状态
export const apiStorehouseStatus = (data: any) =>
    request.post('/warehouse.warehouse/edit', data)
// 添加仓库
export const apiStorehouseAdd = (data: any) =>
    request.post('/warehouse.warehouse/add', data)
// 仓库编辑
export const apiStorehouseEdit = (data: any) =>
    request.post('/warehouse.warehouse/edit', data)
// 仓库删除
export const apiStorehouseDel = (params: any) =>
    request.get('/warehouse.warehouse/del', { params })
// 仓库详情
export const apiStorehouseDetail = (id: number) =>
    request.get('/warehouse.warehouse/detail', { params: { id } })

// 小区管理列表
export const apiCommunityList = (params: any) =>
    request.get('/community.community/lists', { params })
// 修改小区状态
export const apiCommunityStatus = (data: any) =>
    request.post('/community.community/edit', data)
// 添加小区
export const apiCommunityAdd = (data: any) =>
    request.post('/community.community/add', data)
// 小区编辑
export const apiCommunityEdit = (data: any) =>
    request.post('/community.community/edit', data)
// 小区删除
export const apiCommunityDel = (params: any) =>
    request.get('/community.community/del', { params })
// 小区详情
export const apiCommunityDetail = (id: number) =>
    request.get('/community.community/detail', { params: { id } })

// 配送员管理列表
export const apiDeliverymanList = (params: any) =>
    request.get('/deliveryman.deliveryman/lists', { params })
// 修改配送员状态
export const apiDeliverymanStatus = (data: any) =>
    request.post('/deliveryman.deliveryman/edit', data)
// 添加配送员
export const apiDeliverymanAdd = (data: any) =>
    request.post('/deliveryman.deliveryman/add', data)
// 配送员编辑
export const apiDeliverymanEdit = (data: any) =>
    request.post('/deliveryman.deliveryman/edit', data)
// 配送员删除
export const apiDeliverymanDel = (params: any) =>
    request.get('/deliveryman.deliveryman/del', { params })
// 配送员详情
export const apiDeliverymanDetail = (id: number) =>
    request.get('/deliveryman.deliveryman/detail', { params: { deliveryman_id:id } })
// 配送员小区列表
export const apiGetCommunity = (params: any) =>
    request.get('/deliveryman.deliveryman/getCommunity', { params })
// 配送员小区添加
export const addCommunityDeliveryman = (params: any) =>
    request.post('/deliveryman.deliveryman/addCommunityDeliveryman', params)

// 配送员修改密码
export const changeDeliverymanPassword = (params: any) =>
    request.post('/deliveryman.deliveryman/editPassword', params)

// 配送报表
export const apiReportList = (params: any) =>
    request.get('/delivery_report.delivery_report/lists', { params })
// 站点配送报表
export const apiWarehouseReportList = (params: any) =>
    request.get('/delivery_report.delivery_report/warehouseList', { params })

// 配送详情列表
export const apiReportDetailList = (params: any) =>
    request.get('/delivery_detail.delivery_detail/getDeliveryDetailList', {
        params
    })
// 配送详情列表中的详情
export const apiOrderDetail = (params: any) =>
    request.get('/delivery_detail.delivery_detail/getOrderDetail', { params })
// 配送历史
export const apiDeliveryRecordList = (params: any) =>
    request.get('/delivery_detail.delivery_detail/getDeliveryRecordList', { params })
// 所有商品列表
export const apiGoodsAll = () =>
    request.get('/goods.goods/all')
// 添加配送历史备注
export const apiRemark = (params: any) =>
    request.post('/delivery_detail.delivery_detail/addDeliveryRecordRemark', params)

// S 商品管理
// 商品列表
export const apiGoodsAdd = (data: any) => request.post('/goods.goods/add', data)

// 商品编辑
export const apiGoodsEdit = (data: any) =>
    request.post('/goods.goods/edit', data)

// 商品列表
export const apiGoodsLists = (params: any) =>
    request.get('/goods.goods/lists', { params })
// 商品列表
export const apiGoodsCommonLists = (params: any) =>
    request.get('/goods.goods/commonLists', { params })

// 商品状态
export const apiGoodsStatus = (data: any) =>
    request.post('/goods.goods/status', data)

// 删除商品
export const apiGoodsDel = (data: any) => request.post('/goods.goods/del', data)

// 商品排序
export const apiGoodsSort = (data: any) =>
    request.post('/goods.goods/sort', data)

// 商品详情
export const apiGoodsDetail = (id: number) =>
    request.get('/goods.goods/detail', { params: { id } })

// 商品分类/单位/供货商/品牌/运费模板列表
// 商品列表
export const apiGoodsOtherList = (params: any) =>
    request.get('/goods.goods/otherList', { params })

// 修改商品名称
export const apiGoodsRename = (data: any) =>
    request.post('goods.goods/rename ', data)

// E 商品管理

// S 品牌管理
// 新增品牌
export const apiBrandAdd = (data: any) =>
    request.post('/goods.goods_brand/add', data)

// 品牌列表
export const apiBrandLists = (params: any) =>
    request.get('/goods.goods_brand/lists', { params })

// 修改品牌状态
export const apiBrandStatus = (data: any) =>
    request.post('/goods.goods_brand/status', data)

// 删除品牌
export const apiBrandDel = (data: any) =>
    request.post('/goods.goods_brand/del', data)

// 商品详情
export const apiBrandDetail = (id: number) =>
    request.get('/goods.goods_brand/detail', { params: { id } })

// 删除品牌
export const apiBrandEdit = (data: any) =>
    request.post('/goods.goods_brand/edit', data)

// E 品牌管理

// S 供应商管理
// 供应商分类添加
export const apiSupplierCategoryAdd = (data: any) =>
    request.post('/goods.goods_supplier_category/add', data)

// 供应商分类列表
export const apiSupplierCategoryLists = (params: any) =>
    request.get('goods.goods_supplier_category/lists', { params })

// 供应商分类删除
export const apiSupplierCategoryDel = (id: number) =>
    request.post('goods.goods_supplier_category/del', { id })

// 供应商分类编辑
export const apiSupplierCategoryEdit = (data: any) =>
    request.post('goods.goods_supplier_category/edit', data)

// 添加供应商
export const apiSupplierAdd = (data: any) =>
    request.post('/goods.goods_supplier/add', data)

// 供应商列表
export const apiSupplierLists = (params: any) =>
    request.get('/goods.goods_supplier/lists', { params })

// 供应商删除
export const apiSupplierDel = (id: number) =>
    request.post('goods.goods_supplier/del', { id })

// 供应商详情
export const apiSupplierDetail = (id: number) =>
    request.get('/goods.goods_supplier/detail', { params: { id } })

// 供应商编辑
export const apiSupplierEdit = (data: any) =>
    request.post('/goods.goods_supplier/edit', data)

// E 供应商管理

// S 商品分类
// 商品分类添加
export const apiCategoryAdd = (data: any) =>
    request.post('/goods.goods_category/add', data)

// 商品分类列表
export const apiCategoryLists = (params: any) =>
    request.get('/goods.goods_category/lists', { params })

// 商品分类列表
export const apiCategoryCommonLists = (params: any) =>
    request.get('/goods.goods_category/commonLists', { params })

// 修改分类状态
export const apiCategoryStatus = (data: any) =>
    request.post('/goods.goods_category/status', data)

// 分类删除
export const apiCategoryDel = (id: number) =>
    request.post('goods.goods_category/del', { id })

// 商品分类编辑
export const apiCategoryEdit = (data: any) =>
    request.post('/goods.goods_category/edit', data)

// 商品分类编辑
export const apiCategoryDetail = (id: number) =>
    request.get('/goods.goods_category/detail', { params: { id } })

// E 商品分类

// S 商品单位
// 商品单位新增
export const apiUnitAdd = (data: any) =>
    request.post('/goods.goods_unit/add', data)

// 商品单位列表
export const apiUnitLists = (params: any) =>
    request.get('/goods.goods_unit/lists', { params })

// 商品单位删除
export const apiUnitDel = (id: number) =>
    request.post('goods.goods_unit/del', { id })

// 商品单位编辑
export const apiUnitEdit = (data: any) =>
    request.post('/goods.goods_unit/edit', data)

// E 商品单位

// S 商品评价

// 商品评价列表
export const apiGoodsCommentLists = (params: any) =>
    request.get('goods.goods_comment/lists', { params })

// 商品评价删除
export const apiGoodsCommentDel = (data: any) =>
    request.post('goods.goods_comment/del', data)

// 商品评价商家回复
export const apiGoodsCommentReply = (data: any) =>
    request.post('goods.goods_comment/reply', data)

// 商品评价状态
export const apiGoodsCommentStatus = (data: any) =>
    request.post('/goods.goods_comment/status', data)

// 虚拟评价列表
export const apiGoodsCommentAssistantLists = (params: any) =>
    request.get('goods.goods_comment_assistant/lists', { params })

// 添加虚拟评价
export const apiGoodsCommentAssistantAdd = (data: any) =>
    request.post('goods.goods_comment_assistant/add', data)

// E 商品评价
