
















































































































































import { Component, Vue } from 'vue-property-decorator'
import { apiGoodsCommentAssistantLists, apiGoodsOtherList } from '@/api/goods'
import { RequestPaging, timeFormat } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import DatePicker from '@/components/date-picker.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LsDialog,
        LsPagination,
        DatePicker,
        ExportData
    }
})
export default class Evaluation extends Vue {
    $refs!: { table: any }
    pager = new RequestPaging()
    reply = ''
    categoryList = []
    queryObj: any = {
        goods_name: '',
        status: '',
        category_id: ''
    }
    apiGoodsCommentAssistantLists = apiGoodsCommentAssistantLists

    getList(size?: number): void {
        size && (this.pager.page = size)
        this.pager.request({
            callback: apiGoodsCommentAssistantLists,
            params: {
                ...this.queryObj
            }
        })
    }

    handleReset() {
        this.queryObj = {
            goods_name: '',
            status: '',
            category_id: ''
        }
        this.getList()
    }
    getGoodsOtherList() {
        apiGoodsOtherList({
            type: 'list'
        }).then((res: any) => {
            this.categoryList = res?.category_list
        })
    }
    created() {
        this.getGoodsOtherList()
        this.getList()
    }
}
